// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-tsx": () => import("/opt/build/repo/src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-salon-tsx": () => import("/opt/build/repo/src/pages/salon.tsx" /* webpackChunkName: "component---src-pages-salon-tsx" */),
  "component---src-pages-service-tsx": () => import("/opt/build/repo/src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

